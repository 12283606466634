<div *ngIf="isShow" id="accept-cookie">
  <div class="accept-cookie-content">
    <div id="cookie-banner-lgpd">
      <div class="cookie-banner-lgpd_text">
        <p>Nós utilizamos cookies e tecnologias semelhantes que armazenam dados no seu dispositivo para personalizar,
          mensurar e entregar conteúdo e anúncios, analisar o uso e melhorar a sua experiência, seguindo a nossa
          <a routerLink="/politica-de-privacidade" (click)="visiblePopup('none')">Política de Privacidade</a>.
        <p>
          <!-- <p>Clique em "Aceitar" para permitir ou acesse "Gerenciar preferências" para outras opções.</p> -->
        <p>Clique em "Aceitar" para permitir.</p>
      </div>
      <div class="cookie-banner-lgpd_button">
        <!-- <button class="cookie-banner-lgpd_button_gerenciar_preferencias">Gerenciar preferências</button> -->
        <button (click)="handleClick()" class="cookie-banner-lgpd_button_aceitar">Aceitar</button>
      </div>
    </div>
  </div>
</div>