import { OnInit, Component } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  templateUrl: './accept-cookie.component.html',
  styleUrls: ['./accept-cookie.component.scss'],
  selector: 'app-accept-cookie'
})
export class AcceptCookieComponent implements OnInit {
  public isShow: boolean
  private cookieName = 'accept_cookies'
  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    if (!!this.getCookie(this.cookieName) === false) {
      this.isShow = true
    } else {
      this.isShow = false
    }
    
  }

  handleClick() {
    this.setCookie('true')
    this.isShow = false
  }

  setCookie(identifier: string): any {
    const CookieDate = new Date;
    CookieDate.setFullYear(CookieDate.getFullYear() + 5)
    document.cookie = `${this.cookieName}=${identifier}; expires=` + CookieDate.toUTCString() + ';'
    this.visiblePopup('none')
  }

  getCookie(cookie: string): string | undefined {
    const cookies: string = `; ${document.cookie}`
    const cookieParts: string[] | undefined = cookies.split(`; ${cookie}=`)
    if (cookieParts.length !== 2) {
      return undefined
    }
    return cookieParts
      .pop()
      .split(';')
      .shift()
  }

  visiblePopup(display: string){
    document.getElementById("accept-cookie").style.display = display
  }
}